import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants/constants";

const useRegister = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const registerUser = async (data) => {
    setLoading(true);
    setError(false);
    try {
      const res = await axios("http://hospitalsconnect.co/api/user", {
        method: "post",
        data,
      });
      setResponse(res);
      console.log("res from register", res);
      toast.success("User created successfully");
    } catch (error) {
      console.log(error, "err");
      setError(true);
      toast.error("Error creating account");
    }
    setLoading(false);
  };

  const registerFacility = async (data) => {
    setLoading(true);
    setError(false);
    try {
      let res = await fetch(BASE_URL + "/user/facility/create", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      res = await res.json();
      setResponse(res);
      // console.log("res from register", res);
      toast.success(res.message);
    } catch (error) {
      console.log(error, "err");
      setError(true);
      toast.error("Error creating account");
    }
    setLoading(false);
  };

  return { registerUser, registerFacility, response, error, loading };
};

export default useRegister;
