export const HomeArticleData = [
  {
    img: require("../../assets/img/article.jpg"),
    title: "By Michel MD",
    content: "Health Care That Convinient And Affordable",
    link: "Read More",
    date: "23 DEC",
  },
  {
    img: require("../../assets/img/article.jpg"),
    title: "By Michel MD",
    content: "Health Care That Convinient And Affordable",
    link: "Read More",
    date: "23 DEC",
  },
  {
    img: require("../../assets/img/article.jpg"),
    title: "By Michel MD",
    content: "Health Care That Convinient And Affordable",
    link: "Read More",
    date: "23 DEC",
  },
  {
    img: require("../../assets/img/article.jpg"),
    title: "By Michel MD",
    content: "Health Care That Convinient And Affordable",
    link: "Read More",
    date: "23 DEC",
  },
];

export const TopDoctors = [
  {
    img: require("../../assets/img/offerCardBanner.png"),
    title: "Cancer Care",
    heart: require("../../assets/img/whiteHeart.png"),
    eyes: require("../../assets/img/eye.png"),
    available: "Sale",
    buy: require("../../assets/img/buyIcon.png"),
    star: require("../../assets/img/onestar.png"),
    rating: "4.9",
    sndTitle: "Emergency Case",
    dec: "We focus on ergonomics and meeting you where you work. It's only a keystroke away.",
    download: require("../../assets/img/download.png"),
    sale: "15 Sales",

    dollar: "$16.48",
    dollarsnd: "$6.48",
    learn: "learn More",
    arrow: require("../../assets/img/arrow.png"),
  },
  {
    img: require("../../assets/img/offerCardBanner.png"),
    title: "Cancer Care",
    heart: require("../../assets/img/whiteHeart.png"),
    eyes: require("../../assets/img/eye.png"),
    available: "Sale",
    buy: require("../../assets/img/buyIcon.png"),
    star: require("../../assets/img/onestar.png"),
    rating: "4.9",
    sndTitle: "Emergency Case",
    dec: "We focus on ergonomics and meeting you where you work. It's only a keystroke away.",
    download: require("../../assets/img/download.png"),
    sale: "15 Sales",

    dollar: "$16.48",
    dollarsnd: "$6.48",
    learn: "learn More",
    arrow: require("../../assets/img/arrow.png"),
  },
];
