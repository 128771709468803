export const DealsData = [
    {
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },
    {
        title : 'EVERCARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },{
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/offerBed.png'
    },{
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },{
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },{
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },{
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },{
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },{
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },{
        title : 'VEDIC LIFECARE HOSPITAL',
        btn : 'Get Now',
        reviews : './assets/img/fiveStars.png',
        img : './assets/img/cardBuilding.png'
    },
]
export const locationData = [
    {
        title : 'Vi'
    },
    {
        title : 'Ikoyi'
    },{
        title : 'Ajah'
    },{
        title : 'Sangotedo'
    },{
        title : 'ikeja'
    },{
        title : 'festac'
    },{
        title : 'ikorodu '
    },{
        title : 'Yaba '
    },{
        title : 'Maryland'
    },{
        title : 'Magodo '
    },{
        title : 'iyana paja'
    },{
        title : 'Abuja'
    },{
        title : 'Porthacourt'
    },
   
]