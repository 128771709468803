import React from "react";
import { Oval } from "react-loader-spinner";

const Loader = ({ loading }) => {
  return (
    <Oval
      height="20"
      width="20"
      strokeWidth={5}
      wrapperClass="mr-1"
      visible={loading}
      secondaryColor="#fff"
      color="#fff"
      ariaLabel="loading"
    />
  );
};

export default Loader;
