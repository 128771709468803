import React from 'react'
import { ToastContainer } from 'react-toastify'
import './index.css'
import "react-toastify/dist/ReactToastify.css";
const SignUpHeaders = () => {
  return (
    <>
       <ToastContainer />
    <div className="App__questionPage-hero">
          <h1 className='xdd-heading'>For health <br/>facilities
          </h1>
          <p>
            A technology product for clinics and hospitals<br/> which lets them deliver an exceptional visit <br/> experience to patients.
          </p>
          <li className='joined-btn'><a href="" className='text'>Join Us</a></li>
    </div>
    </>
  )
}

export default SignUpHeaders