export const MENU = [
  {
    title: "HomePage",
    path: "/",
  },
  {
    title: "Facilities",
    path: "facilities",
  },
  {
    title: "Doctors",
    path: "experts",
  },
  {
    title: "Discounted Deals",
    path: "deals-offer",
  },
  {
    title: "About Us",
    path: "about",
  },
  {
    title: "FAQ",
    path: "faq",
  },
  {
    title: "Contact",
    path: "contact",
  },
  {
    path: "does not exist",
  },
];
