/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import './SignUpHospital.css'
import '../index.css'
import SignUpHeaders from '../SignUpHeaders';
import { useForm } from "react-hook-form";
import Error from '../../common/Error';
import useRegister from '../../../hooks/useRegister';
import Button from '../../common/Button';
import Loader from '../../common/Loader';
import { BASE_URL } from '../../../constants/constants';
const SignUpHospital = () => {

  const [serviceList, setServiceList] = useState([]);
  const [radiologies, setRadiologies] = useState([]);
  const [surgeries, setSurgeries] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [isSurgeries, setIsSurgeries] = useState('no');
  const [isRadiology, setIsRadiology] = useState('no');
  const [isAmbulance, setIsAmbulance] = useState('no');
  const [isLab, setIsLab] = useState('no');
  const [isPharmacy, setIsPharmacy] = useState('no');
  const { registerFacility, loading } = useRegister();


  const getRadiologies = async () => {
    try {
      let response = await fetch(BASE_URL + '/radiologies', {
        method: 'GET',
      });
      response = await response.json();
      if (response.status === 200) {
        setRadiologies(response.data)
      }
    } catch (error) {
      console.log('here is error at, getRadiologies', error)
    }
  }

  const getSpecialities = async () => {
    try {
      let response = await fetch(BASE_URL + '/speciallities', {
        method: 'GET',
      });
      response = await response.json();
      if (response.status === 200) {
        setSpecialities(response.data)
      }
    } catch (error) {
      console.log('here is error at, getRadiologies', error)
    }
  }
  const getServices = async () => {
    try {
      let response = await fetch(BASE_URL + '/common/get/services', {
        method: 'GET',
      });
      response = await response.json();
      if (response.status === 200) {
        setServiceList(response.data)
      }
    } catch (error) {
      console.log('here is error at, getRadiologies', error)
    }
  }
  const getSurgeries = async () => {
    let response = await fetch(BASE_URL + '/surgeries', {
      method: 'GET',
    });
    response = await response.json();
    if (response.status === 200) {
      setSurgeries(response.data)
    }
  }
  useEffect(() => {
    try {
      getRadiologies();
      getSurgeries();
      getServices();
      getSpecialities();
    } catch (error) {
      console.log('here is error', error)
    }

  }, [])


  // const { getData, loading: getLoading, data } = useApi()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const onSubmit = async (data) => {
    console.log('here is dat', data)
    const res = await registerFacility({ ...data, inhouse_pharmacy: isPharmacy, inhouse_laboratary: isLab, inhouse_radiology: isRadiology, ambulance_available: isAmbulance, surgery: isSurgeries, role_id: 2, });
    reset(res);
  };



  return (
    <>
      <section className="App__SignUpHospital App__sec">
        <SignUpHeaders />
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="App___form">
            <h1 className='xdd-heading'>Join 5000+ health facilities already With Us</h1>
            <h4>Sign Up for hospital</h4>
            <form className="form-wrapper">
              <div className="q-form-item q-form-top">
                <div className="q-input-top">
                  <label className="text" htmlFor="">Name of hospital</label>
                  <input className="text" type="text"
                    name='first_name'
                    {...register("first_name", { required: "This field is required" })}
                  />
                  <Error>{errors.name && errors.name.message}</Error>
                </div>
                <div className="q-input-top">
                  <label className="text" htmlFor="">Location of hospital</label>
                  <input className="text" type="text"
                    name='address'
                    {...register("address", { required: "This field is required" })}
                  />
                  <Error>{errors.address && errors.address.message}</Error>
                </div>
              </div>


              <div className="beds-cont-input">

                <label htmlFor="" className='input-label'>No of bed space available (indicate if they are private wards or semi private or general wards )</label>
                <input type="number"
                  name='no_of_beds'
                  {...register("no_of_beds", { required: "This field is required" })}
                />
                <Error>{errors.no_of_beds && errors.no_of_beds.message}</Error>
                <div className="count-input-wrap">
                  <div className="input-cont-box">
                    <label htmlFor="" className='input-label'>PRIVATE WARD</label>
                    <input type="number"
                      name='private_wards'
                      {...register("private_wards", { required: "This field is required" })}
                    />
                    <Error>{errors.private_wards && errors.private_wards.message}</Error>

                  </div>
                  <div className="input-cont-box">
                    <label htmlFor="" className='input-label'>SEMI PRIVATE</label>
                    <input type="number"
                      name='semi_private_wards'
                      {...register("semi_private_wards", { required: "This field is required" })}
                    />
                    <Error>{errors.semi_private_wards && errors.semi_private_wards.message}</Error>

                  </div>
                  <div className="input-cont-box">
                    <label htmlFor="" className='input-label'>GENERAL WARD</label>
                    <input type="number"
                      name='general_wards'
                      {...register("general_wards", { required: "This field is required" })}
                    />
                    <Error>{errors.general_wards && errors.general_wards.message}</Error>
                    {/* general_wards */}

                  </div>
                </div>
              </div>
              {/* <div className="icu-wrap">
                <div className="icu-count">
                  <div className="working-hours">
                    <label htmlFor="">Yes</label>
                    <input type="checkbox"
                    // name='location'
                    // {...register("location", { required: "required" })} 
                    />
                  </div>
                  <div className="working-hours">
                    <label htmlFor="">No</label>
                    <input type="checkbox" name='location'
                    // {...register("location", { required: "required" })}
                    />
                  </div>
                  <div className="input-cont-box">
                    <input type="text"
                      name='location'
                    // {...register("location", { required: "required" })}
                    />
                    <label htmlFor="">SEMI PRIVATE</label>
                  </div>
                  <div className="input-cont-box">
                    <input type="text"
                      name='location'
                    // {...register("location", { required: "required" })}
                    />
                    <label htmlFor="">GENERAL WARD</label>
                  </div>
                </div>
              </div> */}

              <div className="" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                <label htmlFor="" className='text'>Is there a full time Anesthetic for the ICU ?
                </label>
                <div className="align-checkbox">

                  <div className="working-hours">
                    <label htmlFor="">Yes</label>
                    <input type="checkbox"
                      name='icu'
                      value='yes'
                      checked={getValues('icu') === 'yes' ? true : false}
                      onClick={() => reset({ 'icu': 'yes' })}
                    // {...register("icu", { required: "required" })}
                    />
                  </div>
                  <div className="working-hours">
                    <label htmlFor="">No</label>
                    <input type="checkbox"
                      name='icu'
                      value='no'
                      checked={getValues('icu') === 'no' ? true : false}
                      onClick={() => reset({ 'icu': 'no' })}
                    // {...register("icu", { required: "required" })}
                    />
                  </div>
                </div>
              </div>
              <div className="worning-hours-wrap working-hours-align">
                <label htmlFor="" className='text'>Ambulance?   </label>
                <div className="working-hours">
                  <label htmlFor="">Yes</label>
                  <input type="checkbox"
                    name='ambulance_available'
                    value='yes'
                    checked={isAmbulance === 'yes' ? true : false}
                    onClick={() => setIsAmbulance('yes')} />
                </div>
                <div className="working-hours">
                  <label htmlFor="">No</label>
                  <input type="checkbox"
                    name='ambulance_available'
                    value='no'
                    checked={isAmbulance === 'no' ? true : false}
                    // onClick={() => reset({ 'surgery': 'no' })} />
                    onClick={() => setIsAmbulance('no')} />
                </div>
              </div>
              <div className="worning-hours-wrap working-hours-align">
                <label htmlFor="" className='text'>Radiology?   </label>
                <div className="working-hours">
                  <label htmlFor="">Yes</label>
                  <input type="checkbox"
                    name='inhouse_radiology'
                    value='yes'
                    checked={isRadiology === 'yes' ? true : false}
                    onClick={() => setIsRadiology('yes')} />
                </div>
                <div className="working-hours">
                  <label htmlFor="">No</label>
                  <input type="checkbox"
                    name='inhouse_radiology'
                    value='no'
                    checked={isRadiology === 'no' ? true : false}
                    // onClick={() => reset({ 'surgery': 'no' })} />
                    onClick={() => setIsRadiology('no')} />
                </div>
              </div>
              <div className="worning-hours-wrap working-hours-align">
                <label htmlFor="" className='text'>Laboratary?   </label>
                <div className="working-hours">
                  <label htmlFor="">Yes</label>
                  <input type="checkbox"
                    name='inhouse_laboratary'
                    value='yes'
                    checked={isLab === 'yes' ? true : false}
                    onClick={() => setIsLab('yes')} />
                </div>
                <div className="working-hours">
                  <label htmlFor="">No</label>
                  <input type="checkbox"
                    name='inhouse_laboratary'
                    value='no'
                    checked={isLab === 'no' ? true : false}
                    // onClick={() => reset({ 'surgery': 'no' })} />
                    onClick={() => setIsLab('no')} />
                </div>
              </div>
              <div className="worning-hours-wrap working-hours-align">
                <label htmlFor="" className='text'>Pharmacy?   </label>
                <div className="working-hours">
                  <label htmlFor="">Yes</label>
                  <input type="checkbox"
                    name='inhouse_pharmacy'
                    value='yes'
                    checked={isPharmacy === 'yes' ? true : false}
                    onClick={() => setIsPharmacy('yes')} />
                </div>
                <div className="working-hours">
                  <label htmlFor="">No</label>
                  <input type="checkbox"
                    name='inhouse_pharmacy'
                    value='no'
                    checked={isPharmacy === 'no' ? true : false}
                    // onClick={() => reset({ 'surgery': 'no' })} />
                    onClick={() => setIsPharmacy('no')} />
                </div>
              </div>
              <div className="">
                <label htmlFor="" className='text'>Select your Hospital Specialities.  </label>
                <div className="checkBox-btn">
                  {specialities.length > 0 ? specialities.map((item, index) => (
                    <div className="checkboxx">
                      <input type="checkbox" id={item.id}
                        name='speciallities'
                        value={item?.id}
                        {...register("speciallities",)}
                      />
                      <label htmlFor="">{item?.name}</label>
                    </div>
                  )) : null}
                </div>
              </div>
              <div className="">
                <label htmlFor="" className='text'>No of services rendered full time </label>
                <div className="checkBox-btn">
                  {serviceList.length > 0 ? serviceList.map((item, index) => (
                    <div className="checkboxx">
                      <input type="checkbox" id={item.id}
                        name='services'
                        value={item?.id}
                        {...register("services",)}
                      />
                      <label htmlFor="">{item?.name}</label>
                    </div>
                  )) : null}
                  {/* <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">Internal Medicine</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">Gynaecology</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">Physiotherapy</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">GastroEnterology</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">GastroEnterology</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">Cardiology</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">Nephrology</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">Orthopedic</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">Psychology/Psychiatry</label>
                  </div>
                  <div className="checkboxx">
                    <input type="checkbox" name="" id="" />
                    <label htmlFor="">General Medicine</label>
                  </div> */}
                </div>
              </div>
              <div className="worning-hours-wrap wrapper radiology-align working-hours-align">
                <label htmlFor="" className='text'>Radiology available</label>

                {radiologies.length > 0 ? radiologies.map((item, index) => (
                  <div className="working-hours" key={index}>
                    <label htmlFor="">{item?.name} </label>
                    <input type="checkbox" name='radiologies'
                      value={item?.id}
                      {...register("radiologies")}
                    />
                  </div>
                )) : null}

                {/* <div className="working-hours">
                  <label htmlFor=""> Xray</label>
                  <input type="checkbox" />
                </div>
                <div className="working-hours">
                  <label htmlFor="">Mammogram </label>
                  <input type="checkbox" />
                </div>
                <div className="working-hours">
                  <label htmlFor="">Mammogram</label>
                  <input type="checkbox" />
                </div> */}
              </div>
              <div className="worning-hours-wrap working-hours-align">
                <label htmlFor="" className='text'>Surgeries?   </label>
                <div className="working-hours">
                  <label htmlFor="">Yes</label>
                  <input type="checkbox"
                    name='surgery'
                    value='yes'
                    checked={isSurgeries === 'yes' ? true : false}
                    onClick={() => setIsSurgeries('yes')} />
                </div>
                <div className="working-hours">
                  <label htmlFor="">No</label>
                  <input type="checkbox"
                    name='surgery'
                    value='no'
                    checked={isSurgeries === 'no' ? true : false}
                    // onClick={() => reset({ 'surgery': 'no' })} />
                    onClick={() => setIsSurgeries('no')} />
                </div>
              </div>
              <div className="inputGroups">
                <label htmlFor="" className='text'>Inhouse surgeries available</label>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2.2rem', marginBottom: '2.2rem', gap: '2rem', flexWrap: 'wrap' }}>
                  {surgeries.length > 0 ? surgeries.map((item, index) => (
                    <div className="working-hours" key={index}>
                      <label htmlFor="">{item?.name} </label>
                      <input type="checkbox" name='surgeries'
                        value={item?.id}
                        {...register("surgeries")}
                      />
                    </div>
                  )) : null}
                </div>
                {/* <div className="input-group-wrap">
                  <div className="input-box">
                    <input type="text" />
                  </div>
                  <div className="input-box">
                    <input type="text" />
                  </div><div className="input-box">
                    <input type="text" />
                  </div><div className="input-box">
                    <input type="text" />
                  </div><div className="input-box">
                    <input type="text" />
                  </div><div className="input-box">
                    <input type="text" />
                  </div><div className="input-box">
                    <input type="text" />
                  </div><div className="input-box">
                    <input type="text" />
                  </div><div className="input-box">
                    <input type="text" />
                  </div>
                </div> */}
              </div>
              <div className="">
                <label className="text" htmlFor="">Official email address and customer care contact</label>

                <div className="q-form-item q-form-top">
                  <div className="q-input-top">

                    <input className="text" type="email" name='email'
                      {...register("email", { required: "This field is required" })}
                    />
                    <Error>{errors.email && errors.email.message}</Error>
                  </div>
                  {/* <div className="q-input-top align-hospital-input" >

                    <input className="text" type="text" name='location'
                      {...register("location", { required: "required" })} />
                  </div> */}
                </div>
              </div>
              <div className="">
                <label className="text" htmlFor="">Password</label>

                <div className="q-form-item q-form-top">
                  <div className="q-input-top">

                    <input className="text" type="password" name='password'
                      {...register("password", { required: "This field is required" })}
                    />
                    <Error>{errors.password && errors.password.message}</Error>
                  </div>
                  {/* <div className="q-input-top align-hospital-input" >

                    <input className="text" type="text" name='location'
                      {...register("location", { required: "required" })} />
                  </div> */}
                </div>
              </div>
              <div className="">
                <label className="text" htmlFor="">Mobile No</label>

                <div className="q-form-item q-form-top">
                  <div className="q-input-top">

                    <input className="text" type="phone" name='mobile'
                      {...register("mobile", { required: "This field is required" })}
                    />
                    <Error>{errors.password && errors.password.message}</Error>
                  </div>
                  {/* <div className="q-input-top align-hospital-input" >

                    <input className="text" type="text" name='location'
                      {...register("location", { required: "required" })} />
                  </div> */}
                </div>
              </div>
              <div className="q-form-page " >
                <label className="text" htmlFor="">Official Website;</label>
                <input className="text" type="text" name='website'
                  {...register("website", { required: "This field is required" })}
                />
                <Error>{errors.website && errors.website.message}</Error>
              </div>
              <div className="q-form-page " >
                <label className="text" htmlFor="">Name of Medical Director</label>
                <input className="text" type="text" name='medical_director_name'
                  {...register("medical_director_name", { required: "This field is required" })}
                />
                <Error>{errors.medical_director_name && errors.medical_director_name.message}</Error>
              </div>
              <div className="q-form-page email-lobor">
                <label className="text" htmlFor="">Date </label>
                <input className="text" type="date" name='license_date'
                  {...register("license_date", { required: "This field is required" })}
                />
                <Error>{errors.license_date && errors.license_date.message}</Error>
              </div>
              <div className="q-form-page q-btn">
                <Button
                  type='submit'
                  onClick={handleSubmit(onSubmit)}

                  className="flex font-semibold bg-blue text-white p-2 px-5"
                >
                  <Loader loading={loading} />
                  Join Us
                </Button>
                {/* <button type='submit' className='text'>Join Us</button> */}
              </div>
            </form>
          </div>
        </form>

      </section >
    </>
  )
}

export default SignUpHospital