export const ExpertData = [
    {
        id: 1,
        img : './assets/img/special.png'
    },
    {
        id: 2,
        img : './assets/img/special1.png'
    },{
        id: 3,
        img : './assets/img/special2.png'
    },{
        id: 4,
        img : './assets/img/special3.png'
    },{
        id: 5,
        img : './assets/img/special4.png'
    },{
        id: 6,
        img : './assets/img/special5.png'
    },{
        id: 7,
        img : './assets/img/special6.png'
    },{
        id: 8,
        img : './assets/img/special7.png'
    },
    {
        id: 9,
        img : './assets/img/special8.png'
    },
    {
        id: 10,
        img : './assets/img/special9.png'
    },{
        id: 11,
        img : './assets/img/special10.png'
    },{
        id: 12,
        img : './assets/img/special11.png'
    },{
        id: 13,
        img : './assets/img/special12.png'
    },{
        id: 14,
        img : './assets/img/special13.png'
    },{
        id: 15,
        img : './assets/img/special14.png'
    },{
        id: 16,
        img : './assets/img/special15.png'
    },{
        id: 17,
        img : './assets/img/special16.png'
    },{
        id: 18,
        img : './assets/img/special17.png'
    },{
        id: 19,
        img : './assets/img/special18.png'
    },{
        id: 20,
        img : './assets/img/special19.png'
    },{
        id: 21,
        img : './assets/img/special20.png'
    },{
        id: 22,
        img : './assets/img/special21.png'
    },{
        id: 23,
        img : './assets/img/special22.png'
    },
    {
        id: 24,
        img : './assets/img/special23.png'
    },
]