export const ExpertResultdata = [
    {
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor.png'
    },
    {
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor1.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor2.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor3.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor4.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor5.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor6.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor7.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor8.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor9.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor10.png'
    },{
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor11.png'
    },
    {
        title : 'Dr. Deepak Devakar',
        detail : 'Dermatologist, Cosmetologist, Pediatric Dermatologist, Dermatosurgeon, Hair Transplant Surgeon,',
        experience :'18 Years Experience Overall  (14 years as specialist)',
        time : 'Available Today',
        btn : 'Book Appointement',
        icon : '../assets/icons/available.svg',
        doctorimg : '../assets/img/doctor12.png'
    },
]